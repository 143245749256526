import APIServices, { createClientInstance } from ".";
import {
  OpnwalletSvcAccount,
  OpnwalletAccountsClient,
  OpnwalletAssetsClient,
  StatusDto,
  Transaction,
  OpnwalletTransactionsClient,
  TransactionsList,
  TransactionStatus,
  TransactionType,
  WithdrawalRequest,
  OpnwalletSvcAssetBlockchainTokens,
} from "@nestcoinco/onboard-api-gateway-api-client";

class WalletService extends APIServices {
  private accountsClient: OpnwalletAccountsClient;
  private transactionClient: OpnwalletTransactionsClient;
  private assetsClient: OpnwalletAssetsClient;

  constructor() {
    super();
    this.accountsClient = createClientInstance(OpnwalletAccountsClient);

    this.transactionClient = createClientInstance(OpnwalletTransactionsClient);

    this.assetsClient = createClientInstance(OpnwalletAssetsClient);
  }

  async getWallets(): Promise<OpnwalletSvcAccount[]> {
    try {
      const { data } = await this.accountsClient.getMerchantAccounts({
        headers: {
          ...this.headers,
          "x-auth-token": this.getToken(),
        },
      });
      return Promise.resolve(data.accounts);
    } catch (error: any) {
      return this.responseHandler(error.response);
    }
  }

  async getTransactions(
    status?: TransactionStatus[],
    type?: TransactionType[],
    page?: number,
    size?: number
  ): Promise<TransactionsList> {
    try {
      const { data } = await this.transactionClient.getMerchantTransactions(
        status,
        type,
        page,
        size,
        {
          headers: {
            ...this.headers,
            "x-auth-token": this.getToken(),
          },
        }
      );

      return Promise.resolve(data);
    } catch (error: any) {
      return this.responseHandler(error.response);
    }
  }

  async authorizeTransaction(): Promise<StatusDto> {
    try {
      const { data } = await this.transactionClient.authorizeTransaction({
        headers: {
          ...this.headers,
          "x-auth-token": this.getToken(),
        },
      });
      return data;
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }

  async initiateWithdrawal(
    withdrawRequest: WithdrawalRequest
  ): Promise<Transaction> {
    try {
      const { data } = await this.transactionClient.initiateWithdrawal(
        withdrawRequest,
        {
          headers: {
            ...this.headers,
            "x-auth-token": this.getToken(),
          },
        }
      );
      return data;
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }

  async getNetworkTokens(): Promise<OpnwalletSvcAssetBlockchainTokens> {
    try {
      const { data } = await this.assetsClient.getAssetNetworkTokensOpnwallet(
        "USDX",
        {
          headers: {
            ...this.headers,
            "x-auth-token": this.getToken(),
          },
        }
      );
      return data;
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }
}

export default new WalletService();
